import {
  GoogleMap,
  StandaloneSearchBox,
  Marker,
  Circle,
  LoadScript,
} from "@react-google-maps/api"

import Slider from "rc-slider"
import "rc-slider/assets/index.css"

import config from "../../utils/react-google-maps/config.json"
import React, { useCallback, useRef, useState } from "react"
import { debounce } from "lodash"

const containerStyle = {
  width: "100%",
  height: "60vh",
}

function BeeboRiderLocationField({ businessZone, setBusinessZone }) {
  const [center, setCenter] = useState({
    lat: 14.5995,
    lng: 120.9842,
  })
  const [radius, setRadius] = useState(3)
  const [searchBox, setSearchBox] = useState(null)
  const mapRef = useRef()
  const circleRef = useRef()

  const fitBounds = useCallback(
    debounce(() => {
      const bounds = circleRef.current?.state.circle.getBounds()
      if (!bounds) return
      mapRef.current.state.map.fitBounds(bounds)
    }, 300),
    []
  )

  const geocodeCoordinates = (lat, lng, e) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCu10vZtdRHmJ7bxnebSSj7u1LFeMV4GUs`
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const results = data.results
        const {
          city,
          state,
          country,
          postal_code,
        } = results[0].address_components.reduce((acc, component) => {
          if (component.types.includes("locality"))
            acc.city = component.long_name
          else if (component.types.includes("administrative_area_level_1"))
            acc.state = component.long_name
          else if (component.types.includes("country"))
            acc.country = component.long_name
          else if (component.types.includes("postal_code"))
            acc.postal_code = component.long_name
          return acc
        }, {})

        setBusinessZone({
          address: {
            city: city || "",
            country: country || "",
            province: state || "",
            postal_code: postal_code && postal_code.length ? postal_code : "",
            full_address: results[0].formatted_address || "",
            name: results[0].name || "",
            vicinity: results[0].vicinity || "",
            place_id: results[0].place_id || "",
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng(),
            plus_code: results[0].plus_code || "",
          },
        })
      })
      .catch(console.error)
  }

  const onLoad = useCallback(
    function callback(map) {
      fitBounds()
    },
    [fitBounds]
  )

  const onPlacesChanged = () => {
    const result = searchBox.getPlaces()[0]
    setCenter({
      lat: result?.geometry.location.lat(),
      lng: result?.geometry.location.lng(),
    })

    const {
      city,
      state,
      country,
      postal_code,
    } = result?.address_components.reduce((acc, component) => {
      if (component.types.includes("locality")) acc.city = component.long_name
      else if (component.types.includes("administrative_area_level_1"))
        acc.state = component.long_name
      else if (component.types.includes("country"))
        acc.country = component.long_name
      else if (component.types.includes("postal_code"))
        acc.postal_code = component.long_name
      return acc
    }, {})

    setBusinessZone({
      address: {
        city: city || "",
        country: country || "",
        province: state || "",
        postal_code: postal_code && postal_code.length ? postal_code : "",
        full_address: result?.formatted_address || "",
        name: result?.name || "",
        vicinity: result?.vicinity || "",
        place_id: result?.place_id || "",
        latitude: result?.geometry.location.lat(),
        longitude: result?.geometry.location.lng(),
        plus_code: result?.plus_code || "",
      },
    })
  }

  const onSBLoad = ref => {
    setSearchBox(ref)
  }

  return (
    <div className="beebo-rider-location-field">
      <p style={{ marginTop: "-24px", marginBottom: "-24px" }}>
        {businessZone
          ? businessZone?.address?.full_address
          : "Set your business zone"}
      </p>

      <LoadScript
        id="script-loader"
        googleMapsApiKey={"AIzaSyCu10vZtdRHmJ7bxnebSSj7u1LFeMV4GUs"}
        libraries={config.googleMapsLibraries}
      >
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={containerStyle}
          center={center}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            clickableIcons: false,
          }}
          onClick={e => {
            setCenter({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            })

            geocodeCoordinates(e.latLng.lat(), e.latLng.lng(), e)
          }}
          zoom={12}
          onLoad={onLoad}
        >
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
          >
            <input
              type="text"
              placeholder="Type & search a Business Zone here..."
              style={{
                boxSizing: `border-box`,
                border: `1px solid black`,
                width: `300px`,
                height: `48px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px",
              }}
            />
          </StandaloneSearchBox>

          <Marker
            position={{
              lat: center.lat,
              lng: center.lng,
            }}
          />

          <Circle
            ref={circleRef}
            center={center}
            radius={radius * 1000}
            options={{
              strokeColor: "rgba(255,212, 0)",
              fillColor: "rgb(255,212,0)",
              fillOpacity: 0.4,
              strokeOpacity: 0.4,
              clickable: false,
            }}
          />
        </GoogleMap>
      </LoadScript>

      <div>
        <p>{radius < 1 ? `${radius * 1000} meters` : `${radius} kilometers`}</p>
        <Slider
          onChange={setRadius}
          value={radius}
          step={0.1}
          min={0.1}
          max={100}
        />
      </div>
    </div>
  )
}

export default BeeboRiderLocationField
