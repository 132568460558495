import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BeeboGuideFooter from "../../components/beebo-rider/beeboGuideFooter"
import FileIcon from "../../assets/images/beebo-rider/File.png"
import Info from "../../assets/images/info-circle.svg"
import MainBtn from "../../components/button/mainBtn"
import Media from "../../assets/images/beebo-rider/Media.png"
import Tick from "../../assets/images/beebo-rider/Tick.png"
import Ayos from "../../assets/images/beebo-rider/ayos.png"
import BeeboRiderLocationField from "../../components/beebo-rider/beeboRiderLocationField"

const Apply = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [location, setLocation] = useState()
  const [driversLicense, setDriversLicense] = useState(
    "Non-Professional Driver's License"
  )
  const [vehicleType, setVehicleType] = useState("motorcycle")
  const [vehicleModel, setVehicleModel] = useState("")
  const [plateNumber, setPlateNumber] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")

  const fileInputRef = useRef(null)
  const [files, setFiles] = useState([])

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [errors, setErrors] = useState([])

  const handleSubmit = async e => {
    e.preventDefault()
    const tempErrors = []
    const phoneNumberPattern = /^\+63\d{10}$/

    if (!phoneNumberPattern.test(mobileNumber)) {
      tempErrors.push(
        "Invalid phone number. Please follow the +63XXXXXXXXXX format."
      )
    }

    if (password.length < 8) {
      tempErrors.push("Passwords must be longer than 8 characters")
    }

    if (password !== passwordConfirm) {
      tempErrors.push("Passwords do not match")
    }

    if (files.length < 3) {
      tempErrors.push("Expected at least 3 files with submission")
    }
    setErrors(tempErrors)

    if (tempErrors.length === 0) {
      window.scrollTo({ top: 0 })
      setLoading(true)

      const formData = new FormData()

      formData.append("email", email)
      formData.append("full_name", `${firstName} ${lastName}`)
      formData.append("phone_number", mobileNumber)
      formData.append("drivers_license", driversLicense)
      formData.append("vehicle_type", vehicleType)
      formData.append("vehicle", vehicleModel)
      formData.append("plate_number", plateNumber)
      formData.append("base_fare", 50)
      formData.append("password", password)

      // Append business_zone object as a JSON string
      formData.append("business_zone", JSON.stringify(location.address))

      // Append multiple files
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]) // Same key 'files' for multiple files
      }

      try {
        const response = await fetch(
          "https://api.servbees.com/users/rider-apply",
          {
            method: "POST",
            body: formData,
          }
        )

        if (response.ok) {
          setLoading(false)
          setSubmitted(true)
        } else {
          setLoading(false)
          setSubmitted(false)
          throw new Error("There was an error in submitting the form.")
        }
      } catch (error) {
        console.error(
          error.message || "There was an error in submitting the form."
        )
      }
    }
  }

  const handleUploadTextClick = () => {
    fileInputRef.current.click()
  }

  const handleUploadInput = e => {
    e.preventDefault()
    const uploadedFiles = Array.from(e.target.files)
    processFiles(uploadedFiles)
  }

  const processFiles = uploadedFiles => {
    if (uploadedFiles.length > 0) {
      const existingFileNames = files.map(file => file.name)
      const newFiles = uploadedFiles.filter(
        file => !existingFileNames.includes(file.name)
      )
      setFiles(prevFiles => [...prevFiles, ...newFiles])
    }
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TiB", "PiB", "EiB", "ZiB", "YiB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const handleDragOver = e => {
    e.preventDefault()
  }

  const handleDrop = e => {
    e.preventDefault()
    const uploadedFiles = Array.from(e.dataTransfer.files)
    processFiles(uploadedFiles)
  }

  return (
    <Layout className="beebo-apply bg-design vector-dash blue-footer">
      <SEO
        pageTitle="Apply to be a Beebo Rider!"
        title="Beebo Rider | Servbees"
        description="How to become a Beebo Rider"
      />
      <div className="apply-container">
        <div className="beebo-apply-warpper">
          <div className="beebo-apply-content">
            <form className="form-container" onSubmit={handleSubmit}>
              {loading ? (
                <div className="spinner-container">
                  <div className="spinner"></div>
                  Submitting Application
                </div>
              ) : submitted ? (
                <div className="success-container">
                  <div>
                    <img src={Ayos} />
                  </div>
                  <div className="salamat">Salamat sa pag-submit!</div>
                  <div className="hintayin">
                    Hintayin lamang ang tawag mula sa Servbees team.
                  </div>
                  <Link to="/">
                    <MainBtn text="Got it!" type={"button"} />
                  </Link>
                </div>
              ) : (
                <>
                  <div className="apply-header">
                    Beebo Rider Application Form
                  </div>
                  <div style={{ fontSize: 16 }}>
                    Siguruhing tama at legal ang mga impormasyong isasagot.
                  </div>
                  <div style={{ fontSize: 16, display: "flex" }}>
                    <div style={{ color: "red" }}>*&nbsp;</div>
                    Required field
                  </div>

                  <div className="apply-inputs">
                    <div className="apply-input-container">
                      <div className="apply-required-field">
                        First Name/Pangalan
                      </div>
                      <div>
                        <input
                          placeholder="First Name/Pangalan"
                          className="apply-input"
                          required
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="apply-input-container">
                      <div className="apply-required-field">
                        Last name/Apelyido
                      </div>
                      <div>
                        <input
                          placeholder="Last name/Apelyido"
                          className="apply-input"
                          required
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="apply-input-container">
                      <div className="apply-field">Email</div>
                      <div>
                        <input
                          placeholder="Enter your email address"
                          className="apply-input"
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="apply-input-container">
                      <div className="apply-required-field">
                        Mobile Number/Numero ng Telepono
                      </div>
                      <div>
                        <input
                          placeholder="+639951234567"
                          className="apply-input"
                          required
                          value={mobileNumber}
                          onChange={e => setMobileNumber(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="apply-required-field">Location</div>
                  <BeeboRiderLocationField
                    businessZone={location}
                    setBusinessZone={setLocation}
                  />

                  <div className="apply-inputs">
                    <div className="apply-input-container">
                      <div className="apply-required-field">
                        What is your Driver's License?
                      </div>
                      <div>
                        <select
                          className="apply-select"
                          value={driversLicense}
                          onChange={e => setDriversLicense(e.target.value)}
                        >
                          <option>No Driver's License</option>
                          <option>Non-Professional Driver's License</option>
                          <option>Professional Driver's License</option>
                          <option>International Driver's Permit</option>
                          <option>Student Permit</option>
                        </select>
                      </div>
                    </div>
                    <div className="apply-input-container">
                      <div className="apply-required-field">Vehicle Type</div>
                      <div>
                        <select
                          className="apply-select"
                          value={vehicleType}
                          onChange={e => setVehicleType(e.target.value)}
                        >
                          <option>Bike</option>
                          <option>Motorcycle</option>
                          <option>Sedan</option>
                          <option>MPV</option>
                          <option>SUV</option>
                          <option>Truck</option>
                        </select>
                      </div>
                    </div>
                    <div className="apply-input-container">
                      <div className="apply-required-field">
                        Bike/Vehicle Model
                      </div>
                      <div>
                        <input
                          placeholder="2024 Toyota Land Cruiser Prado"
                          className="apply-input"
                          required
                          value={vehicleModel}
                          onChange={e => setVehicleModel(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="apply-input-container">
                      <div className="apply-required-field">Plate Number</div>
                      <div>
                        <input
                          placeholder="ABC 1234"
                          className="apply-input"
                          required
                          value={plateNumber}
                          onChange={e => setPlateNumber(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="apply-required-field">
                    Upload your Driver's License, ORCR, and your updated resume
                  </div>

                  <div
                    className="file-drop-container"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onClick={handleUploadTextClick}
                  >
                    <div>
                      <img src={Media} />
                    </div>
                    <div className="upload-text-wrapper">
                      <span className="trigger-text">
                        Upload files and/or images
                      </span>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleUploadInput}
                        multiple
                      />
                    </div>
                  </div>

                  <div className="file-list-container">
                    {files.length > 0 &&
                      files.map((file, index) => {
                        return (
                          <div
                            key={`[${index}] - ${file.name}`}
                            className="file-box"
                          >
                            <div className="file-icon">
                              <img src={FileIcon} />
                            </div>
                            <div className="file-descriptions">
                              <div className="file-name">{file.name}</div>
                              <div className="file-size">
                                {formatBytes(file.size, 2)}
                              </div>
                            </div>
                            <div className="check-icon">
                              <img
                                src={Tick}
                                style={{ paddingRight: "30px" }}
                              />
                            </div>
                          </div>
                        )
                      })}
                  </div>

                  <div className="apply-inputs">
                    <div className="apply-input-container">
                      <div className="apply-required-field">
                        Enter password here
                      </div>
                      <div>
                        <input
                          placeholder=""
                          className="apply-input"
                          type="password"
                          required
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="apply-input-container">
                      <div className="apply-required-field">
                        Enter password again
                      </div>
                      <div>
                        <input
                          placeholder=""
                          className="apply-input"
                          type="password"
                          required
                          value={passwordConfirm}
                          onChange={e => setPasswordConfirm(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="submit-container">
                    <div>
                      <img src={Info} /> &nbsp;By submitting, you agree to our{" "}
                      <Link to="/privacy-policy" className="privacy-link">
                        Privacy Policy
                      </Link>
                    </div>
                    <div>
                      <MainBtn text="Ipasa ang aplikasyon" type="submit" />
                    </div>
                  </div>
                  {errors.length > 0 && (
                    <div className="error-container">
                      {errors.map((error, index) => {
                        return (
                          <div key={`[Error ${index}] - ${error}`}>{error}</div>
                        )
                      })}
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <BeeboGuideFooter />
    </Layout>
  )
}

export default Apply
